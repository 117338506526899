import { environment } from '@environment/environment';
import { ITask } from '@shared/interfaces/task.interface';
import { ILocation } from '@shared/interfaces/location.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { Location } from '@shared/models/location.model';
import { User } from '@shared/models/user.model';
import { IAsset } from '@shared/interfaces/asset.interface';
import { Asset } from './asset.model';
import { IAddress } from '@shared/interfaces/address.interface';
import { getConvertedDate, getTimezoneShort } from '@shared/helpers/timezone.helper';
import moment from 'moment';
import { IRecurringTask } from '@shared/interfaces/recurring-task.interface';
import { RecurringTask } from './recurring-task.model';
import { IAssetCategory } from '@shared/interfaces/asset-category.interface';

export class Task implements ITask {
  id: string;
  parent: Partial<ILocation>;
  asset?: Partial<IAsset>;
  location: Partial<ILocation>;
  user?: Partial<IUser>;
  createdBy: Partial<IUser>;
  task: string;
  description: string;
  priority: number;
  status: string;
  // pictures: string[];
  documents?: string[];
  isDeleted: boolean;
  isOverdue?: boolean;

  completedAt: Date;

  createdAt: Date;
  updatedAt: Date;
  startDate: number;
  dueDate: number;

  recurrence: string;
  unit?: string;
  notes?: string;
  userType: string;
  checklistId?: string;
  address?: IAddress;
  customizeDates?: boolean;
  recurringTask?: Partial<IRecurringTask>;
  category?: Partial<IAssetCategory>;

  constructor(partial: Partial<ITask>) {
    Object.assign(this, {
      ...partial,
      user: partial.user ? new User(partial.user) : partial.user,
      asset: partial.asset ? new Asset(partial.asset) : partial.asset,
      recurringTask: partial.recurringTask ? new RecurringTask(partial.recurringTask) : partial.recurringTask,
      location: partial.location
        ? new Location(partial.location)
        : partial.location,
      parent:
        partial.location &&
          partial.location.parent &&
          partial.location.parent === 'object'
          ? new Location(partial.location.parent)
          : partial?.location?.parent
    });
  }

  public get buttonStatus() {
    let label = String('Incomplete');
    let icon = String('me-2 lnr-shield-cross');
    let className = String('btn-sm ohq-btn-warning');

    if (this.completedAt) {
      label = 'Completed';
      icon = 'lnr-shield-check me-2';
      className = 'btn-sm ohq-btn-success';
    }

    return {
      label,
      icon,
      class: className
    };
  }

  get getStartDate() {
    if (!this.startDate) { return ''; }
    return getConvertedDate(moment(new Date(this.startDate).getTime() * 1000), this.address[0].lat, this.address[0].lng);
  }

  get pillsPriority(): any {
    let label;
    let className;

    switch (this.priority) {
      case 1:
        label = 'Medium';
        className = 'ohq-badge-warning';
        break;

      case 2:
        label = 'High';
        className = 'ohq-badge-danger';
        break;

      case 0:
      default:
        label = 'Low';
        className = 'ohq-badge-light text-dark';
        break;
    }

    return {
      label,
      class: className
    };
  }

  get getStatus(): any {
    let label;
    let className;

    switch (this.status) {
      case 'OPEN':
        label = 'OPEN';
        className = 'ohq-badge-light text-white open-badge';
        break;
      case 'COMPLETED':
        label = 'COMPLETED';
        className = 'ohq-badge-success';
        break;
      case 'ON_HOLD':
        label = 'ON HOLD';
        className = 'ohq-badge-warning';
        break;
      case 'CLOSED':
        label = 'CLOSED';
        className = 'ohq-badge-light text-white background-dark';
        break;
      case 'IN_PROGRESS':
        label = 'IN PROGRESS';
        className = 'ohq-badge-light blue-status';
        break;
      default:
        label = 'OPEN';
        className = 'ohq-badge-light text-dark';
        break;
    }

    return {
      label,
      class: className
    };
  }

  get getLabel() {
    if (this.recurringTask) {
      return '<span container="body" ><i class="lnr lnr-sync"></i></span> ' + this.task;
    } else {
      return this.task;
    }
  }

  get getLabelAndCheckOverdue() {
    const obj = {
      msg: '',
      isOverdue: false,
      hasUser: !!this.user
    };
    if (!this.dueDate) return obj
    if (this.isOverdue) {
      obj.isOverdue = true;
      obj.msg = getConvertedDate(moment(new Date(this.dueDate).getTime() * 1000), this.address[0].lat, this.address[0].lng) + ' <span container="body" ><i class="lnr lnr-notification text-danger flash"></i></span>';
    } else {
      obj.msg = getConvertedDate(moment(new Date(this.dueDate).getTime() * 1000), this.address[0].lat, this.address[0].lng);
    }
    return obj;
  }

  get getCreatedAt() {
    return getConvertedDate(moment(new Date(this.createdAt)), this.address[0].lat, this.address[0].lng);
  }

  get pillsIsDeleted(): any {
    let label;
    let className;

    switch (this.isDeleted) {
      case true:
        label = 'COMMON.INACTIVE';
        className = 'ohq-badge-danger';
        break;

      default:
        label = 'COMMON.ACTIVE';
        className = 'ohq-badge-success';
        break;
    }

    return {
      label,
      class: className
    };
  }
}
