import { getSerializer } from '@shared/serializers/serializer';

export const emails = {
  type: 'email',
  attributes: [
    'type',
    'subject',
    'title',
    'body',
    'template',
    'cta',
    'url',
    'createdAt',
    'updatedAt',
    'sendDate',
    'isCheckboxSelected',
    'attachments',
    'to'
  ]
  
};

export const EmailsSerializer = getSerializer(emails);
