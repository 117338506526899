import { Injectable } from '@angular/core';
import { ITask } from '@shared/interfaces/task.interface';
import { IFilter } from '@shared/interfaces/filter.interface';
import { Filter } from '@shared/models/filter.model';
import { TasksService } from '@shared/services/tasks.service';
import { LoggerService } from '@shared/services/logger.service';
import { PaginationService } from '@shared/services/pagination/pagination.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PaginationTasksService extends PaginationService<ITask> {

  private static readonly DEFAULT_START_DATE: IFilter = {
    key: 'startDate',
    value: moment().subtract(1, 'month').valueOf() / 1000
  };

  private static readonly DEFAULT_DUE_DATE: IFilter = {
    key: 'endDate',
    value: moment().add(1, 'month').valueOf() / 1000
  };

  private static readonly DEFAULT_WORK_ORDERS_IDS: IFilter = {
    key: 'workOrdersIds',
    value: null
  };

  private static readonly DEFAULT_IS_RECURRING_TASK: IFilter = {
    key: 'isRecurringTask',
    value: null
  };

  private static readonly DEFAULT_IS_DELETED_FILTER: IFilter = {
    label: 'COMMON.ACTIVE',
    key: 'isDeleted',
    value: 'false'
  };

  private static readonly DEFAULT_OWNERSHIP_FILTER: IFilter = {
    label: 'FILTERS.MYSELF',
    key: 'owner',
    value: 'my'
  };

  private static readonly DEFAULT_STATUS_FILTER: IFilter = {
    label: 'FILTERS.STATUS',
    key: 'status',
    value: null
  };

  private static readonly DEFAULT_SEARCH_FILTER: IFilter = {
    key: 'search',
    value: null
  };

  private static readonly DEFAULT_PARENT_LOCATION_FILTER: IFilter = {
    key: 'parent',
    value: null
  };

  private static readonly DEFAULT_SUB_LOCATION_FILTER: IFilter = {
    key: 'location',
    value: null
  };

  private static readonly DEFAULT_POPULATE_RECURRING_TASKS_FILTER: IFilter = {
    key: 'populateRecurringTasks',
    value: true
  };



  private _filtersScope: any;

  constructor(_paginable: TasksService, _logger: LoggerService) {
    super(_paginable, _logger);

    this.setFilters([
      PaginationTasksService.DEFAULT_IS_DELETED_FILTER,
      PaginationTasksService.DEFAULT_OWNERSHIP_FILTER,
      PaginationTasksService.DEFAULT_STATUS_FILTER,
      PaginationTasksService.DEFAULT_SEARCH_FILTER,
      PaginationTasksService.DEFAULT_PARENT_LOCATION_FILTER,
      PaginationTasksService.DEFAULT_SUB_LOCATION_FILTER,
      PaginationTasksService.DEFAULT_START_DATE,
      PaginationTasksService.DEFAULT_DUE_DATE,
      PaginationTasksService.DEFAULT_WORK_ORDERS_IDS,
      PaginationTasksService.DEFAULT_IS_RECURRING_TASK,
      PaginationTasksService.DEFAULT_POPULATE_RECURRING_TASKS_FILTER,
    ]);
  }

  public getFilters(): IFilter[] {
    return this._filtersScope;
  }

  public reset() {
    super.reset();

    this.setFilters([
      PaginationTasksService.DEFAULT_IS_DELETED_FILTER,
      PaginationTasksService.DEFAULT_OWNERSHIP_FILTER,
      PaginationTasksService.DEFAULT_STATUS_FILTER,
      PaginationTasksService.DEFAULT_SEARCH_FILTER,
      PaginationTasksService.DEFAULT_PARENT_LOCATION_FILTER,
      PaginationTasksService.DEFAULT_SUB_LOCATION_FILTER,
      PaginationTasksService.DEFAULT_START_DATE,
      PaginationTasksService.DEFAULT_DUE_DATE,
      PaginationTasksService.DEFAULT_WORK_ORDERS_IDS,
      PaginationTasksService.DEFAULT_IS_RECURRING_TASK,
      PaginationTasksService.DEFAULT_POPULATE_RECURRING_TASKS_FILTER,
    ]);
  }

  public setFilters(filters: Partial<IFilter>[]): void {
    this._filtersScope = filters.map((f) => new Filter(f));
    super.setFilters(this._filtersScope);
  }
}
