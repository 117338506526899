import { ICompany } from '@shared/interfaces/company.interface';
import { IEmail } from '@shared/interfaces/email.interface';
import { ILibrary } from '@shared/interfaces/library.interface';
import { IUser } from '@shared/interfaces/user.interface';

export class Email implements IEmail {
  public id: string;
  public type?: string;
  public subject: string;
  public title?: string;
  public body: string;
  public cta?: string;
  public url?: string;
  public sendDate?: number;
  public company?: Partial<ICompany>;
  public attachments?: ILibrary[];
  public to?: Partial<IUser>[];

  constructor(partial: Partial<IEmail>) {
    Object.assign(this, partial);
  }

  public get openSendEmailModal() {
    return {
      icon: '',
      label: 'Send Email',
      class: 'btn-sm'
    };
  }
}
