import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ModalDirective } from '@shared/directives/modal.directive';
import { roleTypes } from '@shared/helpers/types.helper';
import { IPayload } from '@shared/interfaces/payload.interface';
import { IUser } from '@shared/interfaces/user.interface';
import { CommonEnvironmentsService } from '@shared/services/environments.service';
import { LoggerService } from '@shared/services/logger.service';
import { ILocation } from '@shared/interfaces/location.interface';
import { ToastService } from '@shared/services/toast.service';
import { UsersService } from '@shared/services/users.service';
import jwtDecode from 'jwt-decode';
import { NgxSelectOption } from 'ngx-select-ex';
import { forkJoin, noop, Subject } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { IContact } from '@shared/interfaces/contact.interface';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ContactsService } from '@shared/services/contacts.service';
import { ICompany } from '@shared/interfaces/company.interface';
import { LocationsService } from '@shared/services/locations.service';
import { ILibrary } from '@shared/interfaces/library.interface';
import { UploadOutput } from 'ngx-uploader';
import { IPicture } from '@shared/interfaces/picture.interface';
import { PicturesService } from '@shared/services/pictures.service';
import { LibrariesService } from '@shared/services/libraries.service';
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';
import { IEmail } from '@shared/interfaces/email.interface';
import { EmailsService } from '@shared/services/emails.service';
import { Editor, Toolbar } from 'ngx-editor';

@Component({
  selector: 'app-modals-write-message',
  templateUrl: './modals.write-message.component.html',
  styleUrls: ['./modals.write-message.component.scss'],
})
export class ModalsWriteMessageComponent
  extends ModalDirective<any>
  implements OnInit {
  @Input() public openModal: Subject<any>;
  @ViewChild('form', { static: false }) form: NgForm;
  @Input() public afterClose?: () => any = noop;
  public title = String('Write a message!');
  public description = String('Write a message to one or multiple Occupants!');
  public emailTestTooltipMessage = "";
  public submitKey = String('BUTTONS.SEND_EMAIL');
  public subject = String('');
  public body = String('');
  public toEmails: any = [];
  public disableButtons = Boolean(false);
  public isLoading = Boolean(false);
  public entry: FormGroup;
  public roles: { value: number; label: string }[] = roleTypes;
  public role: number;
  public contacts: Partial<IContact>[] = [];
  public items: any[] = [];
  public locations: Partial<ILocation>[] = [];
  public uploadImages: any[] = [];
  public subLocations: Partial<ILocation>[] = [];
  public file: File;
  public isEditing = Boolean(false);
  public files: NgxFileDropEntry[] = [];
  public documentList: any[] = [];
  public documentListLabels: any[] = [];
  public filesToUpload: NgxFileDropEntry[] = [];
  public isUploading = Boolean(false);
  public isOpen = Boolean(false);
  public showDeviceFiles = Boolean(false);
  public fgSelected: FormGroup;
  public showFields = new FormControl(false);
  public pictureSelected: string;
  public userId: string;
  public timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || '';
  public subLocationsFilters: any[] = [
    {
      key: 'pagination',
      value: false
    }
  ];
  public modules: any = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link']
    ]
  };

  public editor: Editor;
  public toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  public defaultSubLocationValue: string;
  public availableEmails = [

  ];
  public errorMessages = {
    pattern: 'Email must be in format abc@abc.com',
  };
  public validators = [this.checkPattern];
  public config: any = {
    class: 'modal-lg'
  };
  public dataModel: any;
  public editorTextCount = 0;
  public isSubmitted = false;
  public isFieldEmpty = false;
  public libraryList: any[] = [];
  public isMaxLengthLimitReached = false;
  public bsValue = moment().startOf('day').toDate();
  public closeModal: Subject<any> = new Subject<any>();

  public bsConfig: any = {
    containerClass: 'ohq-datepicker',
    dateInputFormat: 'MMMM DD YYYY',
    showWeekNumbers: false,
    adaptivePosition: true,
    minDate: moment(new Date()).toDate(),
    minMode: 'day'
  };
  public config2: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '200px',
    width: 'auto',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Search for Occupants emails',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [],
      ['customClasses', 'insertImage', 'insertVideo', 'toggleEditorMode'],
    ],
  };
  public formDetails = {
    subject: '',
    body: '',
  };
  private readonly constructorName: string = String(this.constructor.name);
  private blob: Blob;
  constructor(
    private readonly _commonEnvironments: CommonEnvironmentsService,
    private readonly _fb: FormBuilder,
    private readonly _logger: LoggerService,
    public readonly _locations: LocationsService,
    public readonly _emails: EmailsService,
    private readonly _pictures: PicturesService,
    private readonly _users: UsersService,
    private readonly _contacts: ContactsService,
    private readonly _toasts: ToastService,
    public readonly _libraries: LibrariesService,
    public dom: DomSanitizer
  ) {
    super();

    this.onGetValueFromAttributes = this.onGetValueFromAttributes.bind(this);
    this.onGetValueFromAttributesLocation = this.onGetValueFromAttributesLocation.bind(this);
    this.onMatOptionSelectSubLocation = this.onMatOptionSelectSubLocation.bind(this);
    this.deletePicture = this.deletePicture.bind(this);
  }


  ngOnInit(): void {
    const payload: IPayload = jwtDecode(this._commonEnvironments.getToken());
    this.userId = payload.id;
    
    this.role = Number(payload.role);
    this.editor = new Editor();

    this.createForm();
    this.getContacts();
    this.getParentLocations();
    this.getDocuments();

    this.openModal.subscribe((e: IEmail) => {
      this.isOpen = false;
      const payload: IPayload = jwtDecode(this._commonEnvironments.getToken());
      this.emailTestTooltipMessage = 'Send an email test to ' + payload.email;

      if (e) {
        // this.title = 'MODALS.EMAILS.EDIT.TITLE';
        // this.description = 'MODALS.EMAILS.EDIT.DESCRIPTION';
        const emailsList = this.contacts.map(item => item.email);
        this.entry.patchValue({ ...e, to: emailsList, documentList: e.attachments.map( item => item?.id), body: e.body  });
        // this.libraryList = e?.attachments || [];

      } 
      // Needed for ngx-quill html <p> issue
      // https://stackoverflow.com/questions/54967663/quilljs-merges-subsequent-p-tags-into-one-with-angular-7-ngx-quill
      setTimeout(() => {
        this.isOpen = true;
      });
    });

  }

  public getContacts() {
    this._contacts.get(1, [{ key: 'userType', value: 'TENANT' }, { key: 'pagination', value: 'false' }]).subscribe(
      (res: IContact[]) => {
        this.contacts = res;
        this.contacts.unshift({
          email: 'All Occupants',
        });
        this.isLoading = false;
      },
      (err: any) => {
        this.isLoading = false;
        console.log('err', err);
      }
    );
  }

  public onMatOptionSelectSubLocation(e: NgxSelectOption[]): void {
    let subLocations;
    if (e.length > 0 && e[e.length - 1].value === '-1') {
      subLocations = ['-1'];
    } else {
      subLocations = e.filter(item => item.value !== '-1').map((ee: NgxSelectOption) => ee.value);
    }

    setTimeout(() => {
      this.entry.patchValue({ subLocations });
    }, 500);

  }

  public selectedDate(date: Date) {
    this.bsValue = date;
  }

  public selectChangesLocations(e: NgxSelectOption[]) {

    let locations;
    if (e.length > 0 && e[e.length - 1].value === '-1') {
      locations = ['-1'];
    } else {
      locations = e.filter(item => item.value !== '-1').map((ee: NgxSelectOption) => ee.value);
    }

    setTimeout(() => {
      this.entry.patchValue({ locations });
      this.getSubLocation();

    }, 500);

  }

  public deletePicture(): void {
    const url2 = `DELETE /pictures/${this.pictureSelected}`;
    this._pictures.delete(this.pictureSelected).subscribe(
      (res: IPicture) => {
        this._logger.info(this.constructorName, url2, res);
        this.isLoading = true;
      },
      (err: any) => {
        this._logger.error(this.constructorName, url2, err);
        this._toasts.error('Tasks deletion failed');
      }
    );
  }

  public onGetValueFromAttributes(e: ICompany) {
    return e.name;
  }

  public onGetValueFromAttributesLocation(e: ILocation) {
    return e.label;
  }

  public removePicture(i: number) {
    this.filesToUpload.splice(i, 1);
    this.files.splice(i, 1);
    this.uploadImages.splice(i, 1);
  }

  public openConfirmModal(fg: FormGroup, id: string) {
    this.fgSelected = fg;
    this.pictureSelected = id;
    this.deletePicture();
  }

  public isImage(filename) {
    return filename.split('.').pop() === 'jpg' ||
      filename.split('.').pop() === 'png' ||
      filename.split('.').pop() === 'jpeg'
      ? true
      : false;
  }


  public isImageType(type) {
    const imageTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/bmp'
    ];
    return imageTypes.includes(type);
  }

  public onUploadOutput(output: UploadOutput) {
    switch (output.type) {
      case 'addedToQueue':
        this._logger.info(this.constructorName, 'File added to queue', output);
        const file = output.file;
        this.blob = new Blob([file.nativeFile], { type: file.type });

        this.startUpload();
        break;
    }
  }

  public openUpload() {
    const el = document.getElementsByClassName('upload-files-tasks')[0] as any;
    el?.click();
  }

  public removeUplodedDocument(id: string) {
    this.libraryList = this.libraryList.filter(item => item.id !== id);
    this.entry.patchValue({
      documents: [...this.entry.get('documents').value.filter(item => item !== id)],
      documentList: [...this.entry.get('documentList').value.filter(item => item.id !== item.id)],
    });
  }

  public onDismiss() {
    this.afterClose();
  }
  

  public startUpload(): void {
    if (this.blob) {
      this.isUploading = true;
      const filters: any = [
        {
          key: 'ownerType',
          value: 'User'
        },
        {
          key: 'type',
          value: 'profile'
        }
      ];
      const url = `POST /pictures`;
      this._pictures.post(filters, this.blob).subscribe(
        (res: IPicture) => {
          this._logger.info(this.constructorName, url, res);
        },
        (err: any) => {
          this._logger.error(this.constructorName, url, err);
          this._toasts.success('User update failed');
        }
      );
    }
  }

  public selectChangesOccupant(e: any[]) {
    let occupants;
    if (e.length > 0 && e[e.length - 1].value === 'All Occupants') {
      occupants = ['All Occupants'];
    } else {
      occupants = e.filter(item => item.value !== 'All Occupants').map((ee: any) => ee.value);
    }
    setTimeout(() => {
      this.entry.patchValue({ occupants });
    }, 500);

  }

  public uploadFiles(files: NgxFileDropEntry[]) {
    this.filesToUpload = [...this.filesToUpload, ...files];
    this.errors = [];
    this.isUploading = true;
    for (const f of files) {
      if (f.fileEntry.isFile) {
        const fileEntry = f.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (file.size < 20000000) {
            this.file = file;
            this.files = [...this.files, this.file as any];
            this.uploadImages.push(URL.createObjectURL(file));
            if (this.entry.get('fileNames').value) {
              this.entry.patchValue({
                fileNames: [...this.entry.get('fileNames').value, file.name]
              });
            } else {
              this.entry.patchValue({
                fileNames: [file.name]
              });
            }
          } else {
            this._toasts.error(`Sorry! The file named "${file.name}"
               exceeds the maximum allowable size of 20MB. Please upload a smaller file to proceed.`);
          }
        });
        this.isUploading = false;
      } else {
        this._toasts.error('Not a file');
        this.isUploading = false;
      }
    }
  }

  public sendTest({ value, valid }: { value: any; valid: boolean }): void {
    const payload: IPayload = jwtDecode(this._commonEnvironments.getToken());
    value.to = payload.email;
    const sendDate = new Date(value.sendDate);
    const hours = new Date(value.sendDateTime).getHours();
    const minutes = new Date(value.sendDateTime).getMinutes();

    sendDate.setHours(hours);
    sendDate.setMinutes(minutes);

    value.sendDate = sendDate.getTime() / 1000;
    if(!value.isCheckboxSelected) {
      value.cta = '';
      value.url = '';
    }
    if (valid) {
      let promises = [];
      this.filesToUpload.forEach((f) => {
        if (f.fileEntry.isFile) {
          const fileEntry = f.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            const filters: any = [
              {
                key: 'label',
                value: file.name
              },
              {
                key: 'assignedUsers',
                value: [this.userId]
              }
            ];
            promises.push(this._libraries.postLibrary(file, filters));
          });
        }
      });
      if (this.filesToUpload.length > 0) {
        this.isUploading = true;
        forkJoin(promises).subscribe(
          (resDocs) => {
            this.isUploading = false;
            promises = [];
            if (!value?.attachments) {
              value.attachments = [];
            }
            value.attachments = [ ...resDocs.map((item: ILibrary) => item.id), ...value.documentList.map((item: ILibrary) => item?.id || item)];
            this.filesToUpload = [];
            
            const url = `${'send'.toUpperCase()} /emails`;

            this._emails['send'](value).subscribe(
              (res: IEmail) => {
                this._logger.info(this.constructorName, url, res);
                this._toasts.success('Email Test Sent');
                this.isLoading = false
              },
              (err: any) => {
                this._logger.error(this.constructorName, url, err);

                if (err.errors) {
                  this.errors = err.errors;
                }
                this._toasts.error('Email Test has failed!');
              }
            );
          },
          (error) => {
            this._toasts.error('Email Test has failed when attaching files!');
            console.error(error.message);
          }
        );
      } else {
        if (!value?.attachments) {
          value.attachments = [];
        }
        value.attachments = [...value.documentList.map((item: ILibrary) => item?.id || item)];
        this.isUploading = false;
        this.isLoading = true;
        promises = [];
        
        const url = `${'send'.toUpperCase()} /emails`;
        this._emails['send'](value).subscribe(
          (res: IEmail) => {
            this._logger.info(this.constructorName, url, res);
            this.isLoading = false;
            this._toasts.success('Email Test Sent!');
            this.disableButtons = false;
          },
          (err: any) => {
            this._logger.error(this.constructorName, url, err);
            this.isLoading = false;
            if (err.errors) {
              this.errors = err.errors;
            }
            this._toasts.error('Email Test has failed!');
          }
        );
      }
    }
  }

  public onSubmit({ value, valid }: { value: any; valid: boolean }): void {
    if(!value.isCheckboxSelected) {
      value.cta = '';
      value.url = '';
    }
    const emailsList = this.contacts.map(item => item.email);

    value.to = (value.occupants[0] === 'All Occupants') ? emailsList : value.occupants;
    const sendDate = new Date(value.sendDate);
    const hours = new Date(value.sendDateTime).getHours();
    const minutes = new Date(value.sendDateTime).getMinutes();

    sendDate.setHours(hours);
    sendDate.setMinutes(minutes);

    value.sendDate = sendDate.getTime() / 1000;
    if (valid) {

      let promises = [];
      this.filesToUpload.forEach((f) => {
        if (f.fileEntry.isFile) {
          const fileEntry = f.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            const filters: any = [
              {
                key: 'label',
                value: file.name
              },
              {
                key: 'assignedUsers',
                value: [this.userId]
              }
            ];
            promises.push(this._libraries.postLibrary(file, filters));
          });
        }
      });
      if (this.filesToUpload.length > 0) {
        this.isUploading = true;
        forkJoin(promises).subscribe(
          (resDocs) => {
            this.isUploading = false;
            promises = [];
            if (!value?.attachments) {
              value.attachments = [];
            }
            value.attachments = [...resDocs.map((item: ILibrary) => item.id), ...value.documentList.map((item: ILibrary) => item?.id || item)];
            // this.entry.patchValue({ documentList: [...resDocs.map((item: ILibrary) => item.id), ...value.documentList.map((item: ILibrary) => item?.id || item)] });
            this.entry.disable();
            this.disableButtons = true;
            const url = `${'send'.toUpperCase()} /emails`;
            
            this._emails['send'](value).subscribe(
              (res: IEmail) => {
                this._logger.info(this.constructorName, url, res);

                this._toasts.success('Email Sent!');
                // this.entry.patchValue({ attachments: [] })
                this.entry.enable();
                this.disableButtons = false;
                this.resetModal();
              },
              (err: any) => {
                this._logger.error(this.constructorName, url, err);
                if (err.errors) {
                  this.errors = err.errors;
                }
                this.entry.enable();
                this.disableButtons = false;

                this._toasts.error('Email has failed!');
              }
            );
          },
          (error) => {
            this._toasts.error('Email has failed when attaching files');
            console.error(error.message);
          }
        );
      } else {
        if (!value?.attachments) {
          value.attachments = [];
        }
        value.attachments = [...value.documentList.map((item: ILibrary) => item?.id || item)];
        this.entry.disable();
        this.disableButtons = true;
        this.isLoading = true;

        const url = `${'send'.toUpperCase()} /emails`;
        
        this._emails['send'](value).subscribe(
          (res: IEmail) => {
            this._logger.info(this.constructorName, url, res);

            this._toasts.success('Email Sent');
            this.isLoading = false;
            this.entry.enable();
            // this.entry.patchValue({ attachments: [] });
            this.disableButtons = false;
            this.resetModal();
          },
          (err: any) => {
            this._logger.error(this.constructorName, url, err);

            if (err.errors) {
              this.errors = err.errors;
            }
            this.isLoading = false;

            this.entry.enable();
            this.disableButtons = false;

            this._toasts.error('Email has failed!');
          }
        );
      }

      
    }

  }


  protected createForm() {
    this.entry = this._fb.group({
      // firstName: ['', Validators.required],
      // lastName: ['', Validators.required],
      files: [''],
      fileNames: [''],
      documentList: [[]],
      sendDate: [moment().toDate()],
      sendDateTime: [moment().toDate()],
      occupants: [[], [Validators.required]],
      locations: [[], []],
      subLocations: [[], []],
      attachments: [[], []],
      isCheckboxSelected: [false],
      subject: [[], [Validators.required]],
      url: [[], []],
      cta: [[], []],
      body: new FormControl(''),
    });
  }


  private getSubLocation() {

    if (this.entry.get('locations').value?.length > 0) {

      this.subLocationsFilters = [
        {
          key: 'parent',
          value: this.entry.get('locations').value[0] === '-1' ?
            this.locations.map(item => item.id.toString()).slice(1) : this.entry.get('locations').value.join(',')
        },
        {
          key: 'pagination',
          value: 'false'
        },
      ];
    }

    const url = `GET /locations`;
    this._locations.getSubLocations(1, this.subLocationsFilters).subscribe(
      (res: ILocation[]) => {
        this._logger.info(this.constructorName, url, res);

        this.subLocations = res.map((l: ILocation) => ({
          id: l.id,
          label: l.label
        }));

        this.subLocations.unshift({
          id: '-1',
          label: 'All Sub-Locations'
        });


        if (this.entry.get('subLocations')?.value?.length < 1) {
          this.entry.patchValue({ subLocations: ['-1'] });
        }

        setTimeout(() => {
          this.entry.patchValue({ occupants: ['All Occupants'] });
        }, 500);
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  private checkPattern(control: FormControl) {
    const patternRegex = new RegExp('/^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/');
    if (patternRegex.test(control.value)) {
      console.log('Match exists.');
    }
    else {
      return { pattern: true };
    }
  }

  private getParentLocations() {
    const locationsFilters: any[] = [
      {
        key: 'pagination',
        value: 'false'
      }
    ];

    const url = `GET /locations`;
    this._locations.get(1, locationsFilters).subscribe(
      (res: ILocation[]) => {
        this._logger.info(this.constructorName, url, res);

        this.locations = res.map((l: ILocation) => ({
          id: l.id,
          label: l.label
        }));
        this.locations.unshift({
          id: '-1',
          label: 'All Locations'
        });
        this.entry.patchValue({ locations: ['-1'] });
      },
      (err: any) => {
        this._logger.error(this.constructorName, url, err);
      }
    );
  }

  public getDocuments() {
    this._libraries
      .get(1, [
        {
          key: 'limit',
          value: 300
        }
      ])
      .subscribe((res) => {
        this.isLoading = false;
        this.documentList = res;
        this.documentListLabels = res.map((res2) => res2.label);
      });
  }

  private setDefaultValues() {
    this.disableButtons = false;

    this.items = [];
    this.files = [];
    this.documentList = [];
    this.documentListLabels = [];
    this.uploadImages = [];
    this.libraryList = [];
    this.uploadImages = [];
    this.filesToUpload = [];
    this.entityForm.reset();
    this.errors = [];
    // this.createForm();
    this.entry.enable();
    
  }


}
